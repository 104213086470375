.App {
  text-align: center;
}
body {
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#textblock {
  background-color: #210002;
  height: 100vh;
}

#textblock-container {
  width: 50%;
  margin: 0 auto;
  padding-top: 70px;
}

#textblock-title {
  color: #ffaf1b;
  font-size: 35px;
  font-weight: 600;
  font-family: "Helvetica Neue";
}

#textblock-content {
  color: #ffaf1b;
  font-size: 20px;
}

#textblock-footer {
  color: #ffaf1b;
  font-size: 15px;
  font-weight: 400;
  position: fixed;
  width: 100%;
  bottom: 0px;
  justify-content:center;
  align-items:center;
  text-align:center;
  margin-bottom: 20px;

}

#textblock-devsense {
  text-decoration: none;
  color: #ffaf1b;
  font-size: 15px;
  font-weight: 600;
}

.animation, .animation_layer {
	height: 1000px;
}

.animation {
  display: block;
	position: relative;
	z-index: 10;
}

.animation_layer {
	background-position: bottom center;
	background-size: auto 1000px;
	/* background-repeat: repeat-x; */
	width: 100%;
  
	position: absolute;
}
.animation_layer.parallax {
	position: fixed;
}

#logo {
  background-image: url(./Images/Logo.png);

}
#line {
  background-image: url(./Images/Line.png);
  
}
#locationtophalf {
  background-image: url(./Images/location-top-half.png);
}
#blacklogowithtext {
  background-image: url(./Images/black-Logo-with-text.png);
}
#bottomleftdesign {
  background-image: url(./Images/Bottom-Left-design.png);
}
#location1 {
  background-image: url(./Images/Location-1.png);
}
#mobilebg {
  background-image: url(./Images/Mobile-BG.png);
}
#mobile {
  background-image: url(./Images/Mobile.png);
}
#locationsm {
  background-image: url(./Images/location-sm.png);
}
#locationbottomleft {
  background-image: url(./Images/location-bottom-left.png);
}
#text1 {
  background-image: url(./Images/Text-1.png);
}
#leftyellowbg {
  background-image: url(./Images/Left-yellow-bottom-bg.png);
}
#instagram {
  background-image: url(./Images/Instagram.png);
}
#facebook {
  background-image: url(./Images/Facebook.png);
}
#twitter {
  background-image: url(./Images/twitter.png);
}
#whatsaapp {
  background-image: url(./Images/whatsapp.png);
}

#logobg {
  background-image: url(./Images/Logo-BG.png);
}

.annvicabs {
  width: 100%;
  height: 100vh; /* This will make the div take up the full height of the viewport */
  overflow-x: hidden; /* Hide horizontal overflow */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds viewport height */
}

@media only screen and (max-width: 1327px) {
  
}

@media only screen and (max-width: 1200px){
  /*Tablets [601px -> 1200px]*/
}
@media only screen and (max-width: 600px){
/*Big smartphones [426px -> 600px]*/
}
@media only screen and (max-width: 425px){
/*Small smartphones [325px -> 425px]*/


}

.col-4 {
  width: 25%;
  float: left;
}